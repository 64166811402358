* {
	box-sizing: border-box;
}

html {
	height: 100%;
	font-size: $fontSize;
}

body {
	font-family: var(--font-family);
	color: var(--text-color);
	background-color: var(--surface-ground);
	margin: 0;
	padding: 0;
	min-height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: none;
	color: var(--primary-color);
}

.layout-theme-light {
	background-color: #edf1f5;
}
