.layout-sidebar {
	position: fixed;
	width: 310px;
	height: calc(100vh - 7rem);
	z-index: 999;
	overflow-y: auto;
	user-select: none;
	top: 6rem;
	left: 18px;
	transition: transform $transitionDuration, left $transitionDuration;
	background-color: var(--surface-overlay);
	border-radius: 12px;
	padding: 1.0rem;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
		0px 1px 4px rgba(0, 0, 0, 0.08);
}

.layout-menu {
	list-style-type: none;
	margin: 0;
	padding: 0;

	li {
		&.layout-menuitem-category {
			margin-top: 0.75rem;

			&:first-child {
				margin-top: 0;
			}
		}

		.layout-menuitem-root-text {
			text-transform: uppercase;
			color: var(--surface-900);
			font-weight: 600;
			margin-bottom: 0.5rem;
			font-size: 0.875rem;
		}

		a {
			cursor: pointer;
			text-decoration: none;
			display: flex;
			align-items: center;
			color: var(--text-color);
			transition: color $transitionDuration;
			border-radius: $borderRadius;
			padding: 0.75rem 1rem;
			transition: background-color 0.15s;

			span {
				margin-left: 0.5rem;
			}

			.menuitem-toggle-icon {
				margin-left: auto;
			}

			&:focus {
				@include focused-inset();
			}

			&:hover {
				background-color: var(--surface-hover);
			}

			&.router-link-exact-active {
				font-weight: 700;
				color: var(--primary-color);
			}
		}

		&.active-menuitem {
			> a {
				.menuitem-toggle-icon {
					&:before {
						content: '\e933';
					}
				}
			}
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			overflow: hidden;

			&.layout-submenu-wrapper-enter {
				max-height: 0;
			}

			&.layout-submenu-wrapper-enter-active {
				overflow: hidden;
				max-height: 1000px;
				transition: max-height 1s ease-in-out;
			}

			&.layout-submenu-wrapper-enter-done {
				transform: none;
			}

			&.layout-submenu-wrapper-exit {
				max-height: 1000px;
			}

			&.layout-submenu-wrapper-exit-active {
				overflow: hidden;
				max-height: 0;
				transition: max-height 0.45s cubic-bezier(0.86, 0, 0.07, 1);
			}

			ul {
				padding-left: 1rem;
			}
		}
	}
}
