html, body {
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    max-width: 100%;
    overflow-x: hidden;
    margin: 0px;
    color: #333333;
}

:focus {
    outline: none;
}

/* width */

::-webkit-scrollbar {
    width: 15px;
}

/* Handle */

::-webkit-scrollbar-thumb {
    color: #006bd3;
    background-color: #fff;
    border: 1px solid #006bd3;
    border-radius: 20px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background-color: #006bd3;
    border: 1px solid #fff;
}

body .p-button {
    color: #ffffff;
    background-color: #006bd3;
    border: 1px solid #006bd3;
    border-radius: 5px;
    font-size: 0.875rem;
    padding: 0.4375rem 0.875rem;
}

body .btn-secondary {
    color: #fff;
    background-color: #8c313e;
    border: 1px solid #8c313e;
    font-size: 0.875rem;
    padding: 0.4375rem 0.875rem;
}

body .btn-secondary:enabled:hover {
    color: #8c313e !important;
    background-color: #fff !important;
    border: 1px solid #8c313e !important;
}

body .p-button:enabled:hover {
    background-color: #fff;
    color: #006bd3;
    border: 1px solid #006bd3
}

body .p-button:enabled:focus {
    outline: 0 none;
    outline-offset: 0px;
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: none;
}

body .p-tabview .p-tabview-nav li .p-tabview-nav-link{
    font-size: 16px !important;
  }

body .p-button:enabled:active {
    outline: 0 none;
    background-color: #fff;
    color: #006bd3;
    border: 1px solid #006bd3
}

body .btn-language {
    color: #ffffff;
    background-color: #006bd3;
    border: 1px solid #006bd3;
    border-radius: 5px;
    font-size: 0.875rem;
    padding: 0.4375rem 0.875rem;
}

body .btn-language:enabled:hover {
    background-color: #fff;
    color: #006bd3;
    border: 1px solid #006bd3
}

body .p-fileupload-choose:not(.p-state-disabled):hover {
    background-color: #fff;
    color: #006bd3;
    border: 1px solid #006bd3;
}

body .p-dialog {
    max-width: 80%;
    min-width: 400px;
}

body .p-dialog .p-dialog-titlebar {
    border: none;
    text-align: center;
      background: linear-gradient(to right, #0b1641, #006bd3);
}

body .p-dialog .p-dialog-titlebar .p-dialog-title {
    color: #fff;
    font-weight: bold;
}

body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
    color: #fff;
}

.p-card-title {
    color: #006bd3;
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: .5em;
}

strong {
    color: #006bd3;
}

body .p-card {
    border-radius: 10px;
}

.p-card-header img {
    width: 30%;
}

body .p-inputtext:enabled:hover:not(.p-state-error) {
    border-color: rgb(50, 80, 160);
}

body .p-slider {
    background-color: #797676c4;
}

body .p-slider .p-slider-range {
    background-color: #ffffff;
}

body .p-slider:not(.p-state-disabled) .p-slider-handle:hover {
    border: 2px solid #006bd3
}

body .p-inputtext:enabled:focus:not(.p-state-error) {
    border-color: rgb(50, 80, 160);
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-state-active {
      background: linear-gradient(to right, #0b1641, #006bd3);
    font-weight: bold;
    color: #006bd3;
}
body .p-paginator .p-paginator-pages .p-paginator-page {
    font-size: 16px;
}

body .p-paginator {
    background-color: #fff;
    // padding: 0;
    border-radius: 4px;
    // border-bottom: 1.5px solid #006bd3;
    border-left: 1px solid #006bd3;
    border-right: 1px solid #006bd3;
    border-top-left-radius: 15px;
}

body .p-dropdown-label{
    font-size: 16px !important;
}

body .p-dropdown-item {
    font-size: 16px !important;
}

body .p-dialog .p-dialog-header .p-dialog-title{
  font-size: 2rem;
}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-state-highlight, body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-state-highlight {
    background-color: #eaeaea;
    color: black;
}

body .p-inputtext {
    width: 100%;
}

label {
    font-size: 14px;
}

body .p-dropdown {
    width: 100%;
}

body .p-fieldset .p-fieldset-legend{
    font-size: 14px !important;
  
  }

input {
    width: 100%;
}

body .p-menu .p-menuitem-link {
    padding: 0.714em 0.50em;
    text-align: left;
}

table {
    border-collapse: collapse;
    width: 100%;
    line-break: anywhere;
}

body .p-table .p-sortable-column.p-state-highlight {
    background-color: #006bd3;
    color: #ffffff;
}

body .p-table .p-table-caption {
    border-bottom: 0 none;
    font-weight: 700;
    border-top-left-radius: 15px;
    margin-bottom: 2px;
}

body .p-table .p-sortable-column:focus {
    box-shadow: none;
}

td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 4px 8px;
    font-size: small;
}

tr:nth-child(even) {
    background-color: #dddddd;
}

.pi{
    font-size: 1.5rem !important;
}
textarea {
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

.p-tooltip .p-tooltip-text {
    width: fit-content;
}

.p-tooltip .p-component {
    z-index: 99999 !important;
}

.desabilitado {
    cursor: not-allowed;
}

/* You can add global styles to this file, and also import other style files */

@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/prismjs/themes/prism-dark.css';

@import 'src/app/styles/layout/_variables';
@import 'src/app/styles/layout/_layout';
@import 'src/app/styles/overrides/_overrides';
@import 'src/app/styles/global/_global';
.layout-wrapper{
    max-height: calc(100% - 17px) !important;
    min-height: calc(100% - 17px) !important;
}
.card{
    margin-top: 7px;
    margin-bottom: 7px;
    margin-right: 7px;
    height: 100%;
    max-height: 100%;
    width: 100%;
}
.divRouter{
    max-height: calc(100% - 57px);
    min-height: calc(100% - 57px);
    width: 100%;
    padding-right: 8px;
    overflow: scroll;
}
.divTopo{
    position: fixed;
}
.alert {
    color: black;
    background-color: #fdd700;
    border-radius: 7px;
    width: fit-content;
}
.layout-main-container {
    height: 100%;
    max-height: 100%;
    margin-top: 0px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 69px 1rem 0rem 4rem;
	transition: margin-left $transitionDuration;
}
.layout-main {
	flex: 1 1 auto;
    max-height: calc(100% - 15px) !important;
    min-height: calc(100% - 15px) !important;
}
